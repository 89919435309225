
import { useLocalstorage } from '../localStorage/store'
import type {PlantingStandard} from '~/models/plantingStandards'
import {PlantingStandardApi} from "~/gen/openapi/sblService";
import {useAxiosClient} from "~/use/axios/client";

const { persist } = useLocalstorage()

const state = reactive({
  plantingstandard: persist('plantingstandard', []),
  lastFetchedPlantingstandard: persist<number>('lastFetchedPlantingstandard', 0),
})

const plantingstandard = computed((): PlantingStandard[] => state.plantingstandard)
const lastFetchedPlantingstandard = computed((): number => state.lastFetchedPlantingstandard)

const clearPlantingStandard = (): void => {
  state.plantingstandard = []
}

const setPlantingstandard = (plantingstandard: PlantingStandard[]): void => {
  state.plantingstandard = plantingstandard
}

const loadPlantingstandard = async (force?: boolean): Promise<void> => {
  if (!force &&
      state.plantingstandard.length &&
      lastFetchedPlantingstandard &&
      lastFetchedPlantingstandard.value > Date.now() - 1000 /*ms*/ * 60 /*seconds*/ * 60 /*minutes*/
  ) {
    return
  }
  const { axiosClient } = useAxiosClient()
  const plantingStandardApi = new PlantingStandardApi(undefined, '', axiosClient.value)

  try {
    const plantingStandard: PlantingStandard[] = (await plantingStandardApi.plantingStandardGetPlantingStandards()).data
    setPlantingstandard(plantingStandard)
    state.lastFetchedPlantingstandard = Date.now()
  } catch (e) {
    console.error('Failed to load planting standard', e)
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePlantingstandardStore = () => ({
  clearPlantingStandard,
  loadPlantingstandard,
  plantingstandard
})
